import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { User } from "styled-icons/feather"
import { Rocket } from "styled-icons/icomoon"
import { Info } from "styled-icons/fa-solid"

const Wrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  ${props =>
    props.small &&
    `
    margin: 0;
    width: auto;
  `}
`
const AnimWrap = styled.div``

const Elem = styled(Link)`
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background: ${props => props.theme.darker};
  color: ${props => props.theme.font};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-5px);
  }
  ${props =>
    props.isActive &&
    `
        color: ${props.theme.accent};
        box-shadow: 0 0 10px ${props.theme.darker};
    `}
`

const Nav = ({ isPage, small }) => {
  const [pathname, setPathname] = useState("/")
  useEffect(() => {
    setPathname(window.location.pathname)
  }, [setPathname])
  return (
    <Wrapper small={small}>
      <AnimWrap className={isPage ? null : "animated fadeInUp delay-04s"}>
        <Elem
          isActive={pathname === "/alex"}
          to="/alex"
          state={{ wasPage: isPage }}
        >
          <User
            className={pathname === "/alex" && "animated tada infinite slow"}
            size="23"
          />
        </Elem>
      </AnimWrap>
      <AnimWrap className={isPage ? null : "animated fadeInUp delay-04s"}>
        <Elem
          isActive={pathname === "/projekte"}
          to="/projekte"
          state={{ wasPage: isPage }}
        >
          <Rocket
            className={
              pathname === "/projekte" &&
              "animated rotateOutUpRight infinite slow"
            }
            size="14"
          />
        </Elem>
      </AnimWrap>
      <AnimWrap className={isPage ? null : "animated fadeInUp delay-04s"}>
        <Elem
          isActive={pathname === "/kontakt"}
          to="/kontakt"
          state={{ wasPage: isPage }}
        >
          <Info
            className={
              pathname === "/kontakt" && "animated jello infinite slow"
            }
            size="14"
          />
        </Elem>
      </AnimWrap>
    </Wrapper>
  )
}

export default Nav
